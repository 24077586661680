<style lang="scss">

</style>
<!--eslint-disable-->
<template>
  <div class="network-page">
    <section v-if="!loading">
      <b-card class="" v-if="network">
        <validation-observer ref="observer" v-slot="{ handleSubmit }">
          <b-form @submit.stop.prevent="handleSubmit(updateNetwork)">

            <section class="row mb-2">
              <section class="network-section col-6">
                <h3>Network Details</h3>

                <div class="network-item">
                  <label>Name</label>
                  <validation-provider
                    v-slot="validationContext"
                    name="Name"
                    :rules="{ required: true, min: 5 }"
                  >
                    <b-form-input
                      v-model="network.name"
                      :state="getValidationState(validationContext)" class="mb-1" placeholder="Enter name*"
                    />

                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                  </validation-provider>
                </div>

                <div class="network-item">
                  <label>Security</label>
                  <b-form-select v-model="network.security" class="mb-2">
                    <b-form-select-option v-for="option in securityOptions" :key="option" :value="option">{{ option }}</b-form-select-option>
                  </b-form-select>
                </div>

                <div class="network-item">
                  <label>MAC Address Randomization Mode</label>
                  <b-form-select v-model="network.mac_randomization_mode" class="mb-2">
                    <b-form-select-option v-for="option in macOptions" :key="option" :value="option">{{ option }}</b-form-select-option>
                  </b-form-select>
                </div>

                <div v-if="canShowPassphrase()" class="network-item">
                  <label>Passphrase</label>
                  <validation-provider
                    v-slot="validationContext"
                    name="Passphrase"
                    :rules="{ required: passphraseRequired(), min: 5 }"
                  >
                    <b-form-input
                      v-model="network.passphrase"
                      :state="getValidationState(validationContext)" class="mb-1" placeholder="Enter passphrase*"
                    />

                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                  </validation-provider>
                </div>

                <div class="network-item">
                  <b-form-checkbox v-model="network.auto_connect" name="check-button" switch>
                    Auto connect
                  </b-form-checkbox>
                </div>
              </section>

              <section v-if="canShowEAP()" class="network-section col-6">
                <h3>EAP Details</h3>

                <div class="network-item">
                  <b-form-checkbox v-model="network.eap_client_cert_type" name="check-button" switch>
                    Include Certificates?
                  </b-form-checkbox>
                </div>

                <div class="network-item">
                  <label>Eap Outter</label>

                  <validation-provider
                    v-slot="validationContext"
                    name="EAP outter"
                    :rules="{ required: true }"
                  >
                    <b-form-select v-model="network.eap_outer" :state="getValidationState(validationContext)" class="mb-2">
                      <b-form-select-option v-for="option in eapOutter" :key="option" :value="option">{{ option }}</b-form-select-option>
                    </b-form-select>

                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                  </validation-provider>
                </div>

                <div class="network-item">
                  <label>Eap Inner</label>

                  <validation-provider
                    v-slot="validationContext"
                    name="EAP inner"
                    :rules="{ required: eapInnerRequired() }"
                  >
                    <b-form-select v-model="network.eap_inner" :state="getValidationState(validationContext)" class="mb-2">
                      <b-form-select-option v-for="option in eapInner" :key="option" :value="option">{{ option }}</b-form-select-option>
                    </b-form-select>

                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                  </validation-provider>
                </div>

                <div class="network-item">
                  <label>Eap identity (optional)</label>
                  <validation-provider
                    v-slot="validationContext"
                    name="EAP identity"
                    :rules="{ }"
                  >
                    <b-form-input v-model="network.eap_identity" :state="getValidationState(validationContext)" class="mb-1" placeholder="Enter eap identity" />
                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                  </validation-provider>
                </div>

                <div class="network-item mb-1">
                  <label>Eap Password (optional)</label>
                  <validation-provider
                    v-slot="validationContext"
                    name="Eap password"
                    :rules="{ }"
                  >
                    <b-input-group>
                      <b-form-input
                        v-model="network.eap_password"
                        :state="getValidationState(validationContext)" class="mb-0" :type="showPassword ? 'text' : 'password'" placeholder="Enter password*"
                      />

                      <b-input-group-append>
                        <b-button @click="showPassword = !showPassword">
                          <i class="fas" :class="showPassword ? 'fa-eye' : 'fa-eye-slash'" />
                        </b-button>
                      </b-input-group-append>

                      <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                    </b-input-group>

                  </validation-provider>
                </div>

                <div class="network-item">
                  <b-form-checkbox v-model="network.eap_save_credentials" name="check-button" switch>
                    Eap save credentials
                  </b-form-checkbox>
                </div>
              </section>

              <section class="network-section col-6">
                <h3>SSID Details</h3>

                <div class="network-item">
                  <label>SSID</label>
                  <validation-provider
                    v-slot="validationContext"
                    name="SSID"
                    :rules="{ required: true }"
                  >
                    <b-form-input v-model="network.ssid" :state="getValidationState(validationContext)" class="mb-1" placeholder="Enter ssid*" />
                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                  </validation-provider>
                </div>

                <div class="network-item">
                  <b-form-checkbox v-model="network.ssid_hidden" name="check-button" switch>
                    SSID hidden
                  </b-form-checkbox>
                </div>
              </section>
            </section>

            <section class="d-inline-flex full-width mt-2">
              <div class="ui-spacer" />
              <b-button class="mr-2" @click="viewNetwork">Cancel</b-button>
              <b-button type="submit" variant="primary">Update network</b-button>
            </section>
          </b-form>
        </validation-observer>
      </b-card>
      <div v-else>Network not found</div>
    </section>

    <section v-else>
      <b-spinner variant="primary" label="Loading networks"></b-spinner>
    </section>
  </div>
</template>
<!--eslint-enable-->

<script>
import { mapState } from 'vuex'
import NetworkService from '@/services/NetworkService'

export default {
  name: 'NetworkEdit',
  components: {},
  props: {
    uid: {
      required: true,
      type: String,
    },
  },
  data() {
    return {
      loading: true,
      network: {
        name: '',
        auto_connect: false,
        eap_client_cert_type: false,
        eap_password: '',
        eap_identity: '',
        eap_inner: 'Automatic',
        eap_outer: '',
        eap_save_credentials: false,
        passphrase: '',
        security: 'None',
        ssid: '',
        ssid_hidden: false,
        mac_randomization_mode: 'Hardware'
      },
      securityOptions: [
        'WEP-PSK',
        'WEP-8021X',
        'WPA-PSK',
        'WPA-EAP',
      ],
      eapInner: [
        'Automatic',
        'MSCHAPv2',
        'PAP',
        'CHAP',
        'GTC',
      ],
      eapOutter: [
        'EAP-AKA',
        'EAP-TLS',
        'EAP-TTLS',
        'EAP-SIM',
        'PEAP',
      ],
      macOptions: [
        'Hardware',
        'Automatic',
      ],
      showPassword: false,
    }
  },
  computed: {
    ...mapState({
      existingNetwork: state => state.network.network,
    }),
  },
  mounted() {
    this.getNetwork();
  },
  methods: {
    async getNetwork() {
      this.loading = true
      await this.$store.dispatch('network/getNetwork', this.uid);
      this.setNetworkFromExisting();
      this.loading = false;
    },
    viewNetwork() {
      this.$router.push({ name: 'network-view', params: { uid: this.existingNetwork.uid } });
    },
    setNetworkFromExisting() {
      // todo eap_password link up
      this.network = { ...this.existingNetwork };
    },
    updateNetwork() {
      NetworkService.updateNetwork(this.existingNetwork.uid, this.network).then(() => {
        this.$toast.success(`Updated network ${this.network.name} Successfully`, {
          toastClassName: ['toast-std', 'success-toast'],
        });

        this.viewNetwork();
      }).catch(err => {
        const res = err.response
        let errorText = 'Could not update network, please refresh and try again'

        if (res && res.data.error) {
          errorText = res.data.error
        }

        this.$toast.error(errorText, {
          toastClassName: ['toast-std', 'warning-toast'],
        })
      });
    },
    eapInnerRequired() {
      const o = this.network.eap_outer;
      return o !== 'EAP-FAST' && o !== 'EAP-TTLS' && o !== 'PEAP';
    },
    passphraseRequired() {
      const s = this.network.security;
      return s === 'WEP-PSK' || s === 'WPA-PSK';
    },
    canShowPassphrase() {
      const s = this.network.security;
      return s !== 'None' && s !== 'WEP-8021X' && s !== 'WPA-EAP';
    },
    canShowEAP() {
      const s = this.network.security;
      return s === 'WEP-8021X' || s === 'WPA-EAP';
    },
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
  },
}
</script>
